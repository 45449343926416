import _get from 'lodash/get';
import { batch } from 'react-redux';
import { type Dispatch } from 'redux';

import { isFlagActivated } from '@/services/flags/useFlag';

import {
  ACTIVATE_IS_LOADING,
  ADD_NOTIFICATION,
  APPLICATION_ACTIVATE_LABELING_FINISHED,
  APPLICATION_DEACTIVATE_LABELING_FINISHED,
  APPLICATION_INITIALIZE,
  APPLICATION_UPDATE_ACCORDION,
  APPLICATION_UPDATE_FIELD,
  APPLICATION_UPDATE_SEARCH_FIELD,
  CLEAN_STATE_FOR_PROJECT_LIST,
  CLEAR_NOTIFICATION,
  CLEAR_UPLOAD_REPORT,
  DEACTIVATE_IS_LOADING,
  FORCE_REFRESH_REVIEW_TABLE,
  HIDE_MAXIMUM_ASSETS_ERROR,
  INITIALIZE_TEMPORARY_RESPONSES,
  SET_AUTHENTICATION_TOKEN,
  SET_IDENTIFY_USER,
  SET_IS_VALID_AUTHENTIFICATION_TOKEN,
  SET_TEMPORARY_RESPONSES,
  SHOW_MAXIMUM_ASSETS_ERROR,
} from './slice';
import {
  type AddNotificationPayload,
  type ApplicationSetAuthenticationTokenPayload,
  type ApplicationState,
  type ApplicationUpdateFieldPayload,
  type SignUpPayload,
  type TemporaryResponse,
} from './types';

import { isUsingSegment, SEGMENT_ANALYTICS } from '../../config';
import { RESET_PASSWORD, SIGNUP } from '../../graphql/auth/mutations';
import { type SegmentEvents } from '../../pages/RootModule/helpers';
import { computeVisibilityTree } from '../../services/jobs/visibilityTree';
import { updateFieldGenerics } from '../../types';
import { dataForSegment } from '../organization/selectors';
import { userId as userIdFromState, projectJobs } from '../selectors';
import { type AppThunk } from '../types';
import { userEmail } from '../user/selectors';

const EMPTY_STRING = '';

export const initializeState = (): { type: string } => APPLICATION_INITIALIZE();

export const cleanStateForProjectList = (): {
  type: string;
} => CLEAN_STATE_FOR_PROJECT_LIST();

export const updateAccordion = (payload: { [key: string]: boolean }): { type: string } =>
  APPLICATION_UPDATE_ACCORDION(payload);

export const updateSearchField = (payload: string): { type: string } =>
  APPLICATION_UPDATE_SEARCH_FIELD(payload);

export const updateField = updateFieldGenerics<ApplicationState>()(APPLICATION_UPDATE_FIELD);

export const updateFieldsInBatch = (payloads: ApplicationUpdateFieldPayload[]): AppThunk => {
  return async dispatch => {
    batch(() => {
      payloads.forEach(payload => dispatch(updateField(payload)));
    });
  };
};

export const initializeJobsVisibilityTree = (): AppThunk => {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const jobs = projectJobs(state);
    const queryString = EMPTY_STRING;
    const searchVisibility = computeVisibilityTree(jobs, {}, queryString, false);
    dispatch(updateField({ path: 'searchVisibility', value: searchVisibility }));
  };
};

export const setAuthenticationToken = (
  payload: ApplicationSetAuthenticationTokenPayload,
): {
  type: string;
} => SET_AUTHENTICATION_TOKEN(payload.token);

export const addNotification = (
  payload: AddNotificationPayload,
): { payload: AddNotificationPayload; type: string } => {
  return ADD_NOTIFICATION(payload);
};

export const signUp = (payload: SignUpPayload): AppThunk => {
  const actionId = `signUp`;
  return async (dispatch: Dispatch) => {
    const { client, email } = payload;
    try {
      await client.mutate({
        context: {
          clientName: 'V2',
          headers: {
            actionId,
          },
        },
        mutation: SIGNUP,
        variables: { data: { email } },
      });
      dispatch(
        addNotification({
          message: 'An email containing your password has been sent, check your mailbox',
          variant: 'success',
        }),
      );
    } catch {
      dispatch(
        addNotification({
          message: 'Email already used: please, try again',
          variant: 'info',
        }),
      );
    }
  };
};

export const resetPassword = (payload: SignUpPayload): AppThunk => {
  const actionId = `resetPassword`;
  return async (dispatch: Dispatch) => {
    const { client, email } = payload;
    try {
      await client.mutate({
        context: {
          headers: {
            actionId,
          },
        },
        mutation: RESET_PASSWORD,
        variables: {
          where: { email },
        },
      });
      dispatch(
        addNotification({
          message: 'Email sent, check your mailbox',
          variant: 'success',
        }),
      );
    } catch {
      dispatch(
        addNotification({
          message: 'Wrong email: please, try again',
          variant: 'info',
        }),
      );
    }
  };
};

export const activateIsLoading = (): {
  type: string;
} => ACTIVATE_IS_LOADING();

export const deactivateIsLoading = (): {
  type: string;
} => DEACTIVATE_IS_LOADING();

export const activateLabelingFinished = (
  inReview: boolean,
): {
  type: string;
} => APPLICATION_ACTIVATE_LABELING_FINISHED(inReview);

export const deactivateLabelingFinished = (): {
  type: string;
} => APPLICATION_DEACTIVATE_LABELING_FINISHED();

export const showMaximumAssetsError = (): {
  type: string;
} => SHOW_MAXIMUM_ASSETS_ERROR();

export const hideMaximumAssetsError = (): {
  type: string;
} => HIDE_MAXIMUM_ASSETS_ERROR();

export const addErrorNotification = (
  message = 'It seems you have an issue. Please, refresh page or go back to homepage if it persists.',
  persist = false,
): {
  payload: AddNotificationPayload;
  type: string;
} =>
  addNotification({
    message,
    persist,
    variant: 'warning',
  });

export const addSuccessNotification = (
  message = 'Operation is successful.',
  persist = false,
): {
  payload: AddNotificationPayload;
  type: string;
} =>
  addNotification({
    message,
    persist,
    variant: 'success',
  });

export const clearNotification = (): {
  type: string;
} => {
  return CLEAR_NOTIFICATION();
};

export const clearUploadReport = (): {
  type: string;
} => {
  return CLEAR_UPLOAD_REPORT();
};

export const forceRefreshReviewTable = (): {
  type: string;
} => FORCE_REFRESH_REVIEW_TABLE();

export const setTemporaryResponses = (
  payload: TemporaryResponse,
): { payload: TemporaryResponse; type: string } => SET_TEMPORARY_RESPONSES(payload);

export const initializeTemporaryResponses = (): { type: string } =>
  INITIALIZE_TEMPORARY_RESPONSES();

export const setIsValidAuthentificationToken = (
  payload: boolean,
): { payload: boolean; type: string } => SET_IS_VALID_AUTHENTIFICATION_TOKEN(payload);

export const setCanUndo = (canUndo: boolean): AppThunk => {
  return async dispatch => {
    dispatch(APPLICATION_UPDATE_FIELD({ path: 'canUndo', value: canUndo }));
  };
};

export const identifyProjectUserRole = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = userIdFromState(state) || undefined;
    const email = userEmail(state) || undefined;

    if (!userId || !email) {
      return;
    }

    dispatch(SET_IDENTIFY_USER());
  };
};

export const sendToSegment = (payload: {
  event: SegmentEvents;
  properties?: Record<string, unknown>;
}): AppThunk => {
  return (dispatch, getState) => {
    const segmentAnalytics = _get(window, SEGMENT_ANALYTICS);
    if (
      isUsingSegment() &&
      isFlagActivated('config_event_segment') &&
      segmentAnalytics &&
      segmentAnalytics.track
    ) {
      const { properties, event } = payload;
      const allProperties = dataForSegment(getState(), { event, propertiesForSegment: properties });
      segmentAnalytics.track(event, allProperties);
    }
  };
};

import { type FetchPolicy } from '@apollo/client';

import { clientQuery } from '@/apollo';
import { GQL_GET_LABELS_FOR_USER } from '@/graphql/asset/queries';
import { type GetLabelsToBeReviewedQuery } from '@/graphql/label/__generated__/queries.graphql';
import { GQL_GET_LABELS_TO_BE_REVIEWED } from '@/graphql/label/queries';
import { store } from '@/store';
import { labelSaveUpdateField } from '@/zustand';

import { type GetLabelOrPredictionForUserPayload, type ExportDataPayload } from './types';

import { type Label, LabelType } from '../../__generated__/globalTypes';
import { GQL_PROJECT_EXPORT_DATA } from '../../graphql/project/queries';
import { xdate } from '../../helpers/xdate';
import { applicationInExplore, applicationInReview } from '../application/selectors';
import { userId } from '../selectors';

export const takeLatestLabel = (labels: Label[]): Label | null => {
  const sortedLabels = labels.sort((a: Label, b: Label) =>
    xdate(b.createdAt) > xdate(a.createdAt) ? 1 : -1,
  );
  let latestLabel: Label | null = null;
  const labelTypes = [
    LabelType.REVIEW,
    LabelType.DEFAULT,
    LabelType.AUTOSAVE,
    LabelType.PREDICTION,
  ];
  while (labelTypes.length > 0) {
    const labelType = labelTypes.shift();
    const labelsWithLabelType = sortedLabels.filter(label => label.labelType === labelType);
    if (labelsWithLabelType && labelsWithLabelType.length) {
      [latestLabel] = labelsWithLabelType;
      break;
    }
  }
  return latestLabel;
};

export const getLabelOrPredictionForUser = async (
  { assetID, client, labelID, userID: paramUserId }: GetLabelOrPredictionForUserPayload,
  fetchPolicy: FetchPolicy = 'cache-first',
) => {
  const actionId = `getLabelOrPredictionForUser`;
  const state = store.getState();
  const inExplore = applicationInExplore(state);
  const inReview = applicationInReview(state);
  const userID = paramUserId || userId(state);
  let fetchedLabels = null;

  if (userID && !inReview) {
    const response = await clientQuery({
      actionId,
      client,
      clientName: 'V2',
      query: GQL_GET_LABELS_FOR_USER,
      variables: { assetID, labelID, userID },
    });
    const data = response?.data;

    if (!data) return null;
    fetchedLabels = data?.getLabelsOrPredictionsForUser;
  } else if (inExplore || inReview) {
    const response = await client.query<GetLabelsToBeReviewedQuery>({
      context: {
        clientName: 'V2',
        headers: {
          actionId,
        },
      },
      fetchPolicy: labelID !== undefined ? fetchPolicy : undefined,
      query: GQL_GET_LABELS_TO_BE_REVIEWED,
      variables: { assetID, labelID },
    });
    const data = response?.data;

    if (!data) return null;
    fetchedLabels = data?.getLabelsToBeReviewed;
  }

  if (!fetchedLabels) {
    labelSaveUpdateField({ path: 'clientVersion', value: null });
    return null;
  }

  const lastestLabel = takeLatestLabel(fetchedLabels);
  labelSaveUpdateField({ path: 'clientVersion', value: lastestLabel?.clientVersion ?? null });

  return lastestLabel;
};

export const exportData = ({
  asset,
  client,
  exportType,
  includeSentBackLabels,
  labelFormat,
  projectID,
  splitOption,
  versionName,
}: ExportDataPayload) => {
  const actionId = `exportData`;
  return clientQuery({
    actionId,
    client,
    clientName: 'V2',
    query: GQL_PROJECT_EXPORT_DATA,
    variables: {
      exportType,
      includeSentBackLabels,
      labelFormat,
      splitOption,
      versionName,
      where: { asset, id: projectID },
    },
  });
};

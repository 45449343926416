import L from 'leaflet';

import {
  LEAFLET_CURSOR_EDIT_ROTATION_ZINDEX,
  LEAFLET_CURSOR_EDIT_ZINDEX,
} from '@/constants/zIndexes';

import { getProjection } from '../helpers/getProjection';

/* eslint-disable no-underscore-dangle */

export const TRANSPARENT_COLOR = 'rgba(0,0,0,0)';

const RESIZE_CURSORS_LIST = ['nesw-resize', 'nwse-resize', 'nesw-resize', 'nwse-resize'];
const ROTATION_ICON_LINE_LENGTH = 20;

function projectPointOnLine(start: L.Point, final: L.Point, distPx: number) {
  const distance = start.distanceTo(final);
  const ratio = distance === 0 ? 1 : 1 + distPx / distance;
  return new L.Point(start.x + (final.x - start.x) * ratio, start.y + (final.y - start.y) * ratio);
}

const DefaultResizeHandlerClass = L.CircleMarker.extend({
  onAdd(map: L.Map) {
    L.CircleMarker.prototype.onAdd.call(this, map);
    if (this._path && this.options.setCursor) {
      // SVG/VML
      this._path.style.cursor = RESIZE_CURSORS_LIST[this.options.index];
    }
  },
}) as typeof L.CircleMarker;

const DEFAULT_RESIZE_HANDLER_OPTIONS = {
  className: 'leaflet-interactive leaflet-editing-icon',
  color: '#2DC0FF',
  fillColor: '#ffffff',
  fillOpacity: 1,
  radius: 5,
  setCursor: true,
  weight: 2,
};

const DefaultRotateHandlerClass = L.CircleMarker.extend({
  onAdd(map: L.Map) {
    L.CircleMarker.prototype.onAdd.call(this, map);
    if (this._path && this.options.setCursor) {
      // SVG/VML
      this._path.style.cursor = 'all-scroll';
    }
  },

  options: {
    className: 'transform-handler--rotate',
  },
}) as typeof L.CircleMarker;

const DEFAULT_ROTATE_LINE_OPTIONS = {
  color: 'black',
  opacity: 1,
  setCursor: true,
  weight: 1,
};

type ExtendedCircleMarkerOptions = L.CircleMarkerOptions & { index: number; type: number };

L.Handler.PathTransform = L.Handler.PathHandler.extend({
  /**
   * Apply final transformation
   */
  _apply(this: L.Handler.PathTransform) {
    const map = this._map;
    const matrix = this._matrix.clone();
    const angle = this._angle;
    const scale = this._scale.clone();

    this._transformGeometries();

    // update handlers
    for (let i = 0, len = this._handlers.length; i < len; i += 1) {
      const handler = this._handlers[i];
      if (!handler._point) {
        throw new Error('Handler has no point');
      }
      handler._latlng = map.layerPointToLatLng(handler._point);
      delete handler._initialPoint;
      handler.redraw();
    }

    this._matrix = L.matrix(1, 0, 0, 1, 0, 0);
    this._scale = L.point(1, 1);
    this._angle = 0;

    this._updateHandlers();

    map.dragging.enable();
    this._path.fire('transformed', {
      layer: this._path,

      matrix,

      rotation: angle,

      scale,
    });
  },

  /**
   * @param  {L.Matrix} matrix
   */
  _applyTransform(this: L.Handler.PathTransform, matrix: L.Matrix) {
    if (!matrix) {
      return;
    }
    this._path._transform(matrix._matrix);

    this._handleLine?._transform(matrix._matrix);
  },

  /**
   * Cache current handlers positions
   */
  _cachePoints(this: L.Handler.PathTransform) {
    this._handlersGroup?.eachLayer((layer: L.Layer) => {
      if (layer && layer.bringToFront) {
        layer.bringToFront();
      }
    });
    for (let i = 0, len = this._handlers.length; i < len; i += 1) {
      const handler = this._handlers[i];
      handler._initialPoint = handler._point?.clone();
    }
  },

  /**
   * Create one resize handler (leaflet component used to resize the shape)
   */
  _createHandler(this: L.Handler.PathTransform, latlng: L.LatLng, type: number, index: number) {
    const marker = new DefaultResizeHandlerClass(
      latlng,
      L.Util.extend({}, DEFAULT_RESIZE_HANDLER_OPTIONS, {
        index,
        type,
      }),
    );

    marker.on('mousedown', this._onScaleStart, this);
    return marker;
  },

  /**
   * Creates markers and handles
   */
  _createHandlers(this: L.Handler.PathTransform) {
    const map = this._map;
    this._handlersGroup = this._handlersGroup || new L.LayerGroup().addTo(map);

    if (!this._path) {
      throw new Error('No path defined');
    }

    this._handlers = [];
    for (let i = 0; i < 4; i += 1) {
      this._handlers.push(
        this._createHandler(this._path.getLatLngs()[0][i], i * 2, i).addTo(this._handlersGroup),
      );
    }

    this._createRotationHandlers();
  },

  /**
   * Rotation marker and small connectin handle
   */
  _createRotationHandlers(this: L.Handler.PathTransform) {
    if (!this._map) {
      return;
    }

    if (!this._path) {
      throw new Error('No path defined');
    }

    const map = this._map;
    const latlngs = this._path.getLatLngs()[0];

    const bottom = new L.LatLng(
      (latlngs[0].lat + latlngs[3].lat) / 2,
      (latlngs[0].lng + latlngs[3].lng) / 2,
    );
    const topPoint = new L.LatLng(
      (latlngs[1].lat + latlngs[2].lat) / 2,
      (latlngs[1].lng + latlngs[2].lng) / 2,
    );

    const pointOnLine = projectPointOnLine(
      map.latLngToLayerPoint(bottom),
      map.latLngToLayerPoint(topPoint),
      ROTATION_ICON_LINE_LENGTH,
    );
    const handlerPosition = map.layerPointToLatLng(pointOnLine);

    if (!this._handlersGroup) {
      throw new Error('No handlers group');
    }

    this._handleLine = new L.Polyline(
      [topPoint, handlerPosition],
      DEFAULT_ROTATE_LINE_OPTIONS,
    ).addTo(this._handlersGroup);
    this._rotationMarker = new DefaultRotateHandlerClass(handlerPosition)
      .addTo(this._handlersGroup)
      .on('mousedown', this._onRotateStart, this);

    const svgTemplate = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="400" height="448" viewBox="0 0 384 448"><path fill="none" stroke="black" stroke-width="10" d="M192.063 32.063c-105.75 0-192 86.25-192 192 0 57.25 25.25 111.25 69.25 147.75 3.25 2.5 8 2.25 10.75-0.5l34.5-34.25c1.5-1.75 2.25-4 2.25-6.25-0.25-2.25-1.25-4.5-3-5.75-31.75-24.5-49.75-61.25-49.75-101 0-70.5 57.5-128 128-128s128 57.5 128 128c0 32.75-12.5 63.75-34.25 87l-34.5-34.25c-4.5-4.75-11.5-6-17.25-3.5-6 2.5-10 8.25-10 14.75v112c0 8.75 7.25 16 16 16h112c6.5 0 12.25-4 14.75-10 2.5-5.75 1.25-12.75-3.5-17.25l-32.25-32.5c33.25-35.25 53-83 53-132.25 0-105.75-86.25-192-192-192z"></path></svg>`;
    const iconUrl = `data:image/svg+xml;base64,${btoa(svgTemplate)}`;
    const arrowsIcon = L.icon({
      iconSize: [25, 25],
      iconUrl,
    });
    this._rotationIcon = new L.Marker(handlerPosition)
      .setIcon(arrowsIcon)
      .addTo(this._handlersGroup)
      .on('mousedown', this._onRotateStart, this);
    if (this._rotationIcon.setStyle) {
      this._rotationIcon.setStyle({ 'z-index': LEAFLET_CURSOR_EDIT_ZINDEX });
      this._rotationIcon.setStyle({ cursor: 'all-scroll' });
    }

    this._rotationOrigin = new L.LatLng(
      (topPoint.lat + bottom.lat) / 2,
      (topPoint.lng + bottom.lng) / 2,
    );

    if (!this._rotationMarker) {
      throw new Error('No rotation marker');
    }

    this._handlers.push(this._rotationMarker);
  },

  _getProjectedMatrix(
    this: L.Handler.PathTransform,
    angle?: number,
    scale?: L.Point,
    rotationOrigin?: L.LatLng,
    scaleOrigin?: L.LatLng,
  ) {
    if (!this._map) {
      return;
    }
    const map = this._map;
    const zoom = map.getZoom() || 1;
    let matrix = L.matrix(1, 0, 0, 1, 0, 0);
    let origin;

    const angleParam = angle || this._angle || 0;
    const scaleParam = scale || this._scale || L.point(1, 1);
    const scaleOriginParam = scaleOrigin || this._scaleOrigin;
    const rotationOriginParam = rotationOrigin || this._rotationOrigin;

    if (!(scaleParam.x === 1 && scaleParam.y === 1)) {
      if (!scaleOriginParam) {
        throw new Error('Not enough parameters to get projected matrix');
      }
      origin = map.project(scaleOriginParam, zoom);
      matrix = matrix
        ._add(1, 0, 0, 1, origin.x, origin.y)
        ._add(scaleParam.x, 0, 0, scaleParam.y, 0, 0)
        ._add(1, 0, 0, 1, -origin.x, -origin.y);
    }

    if (angleParam) {
      if (!rotationOriginParam) {
        throw new Error('Not enough parameters to get projected matrix');
      }
      origin = map.project(rotationOriginParam, zoom);
      matrix = matrix.rotate(angleParam, origin).flip();
    }

    return matrix;
  },

  /**
   * @return {L.LatLng}
   */
  _getRotationOrigin(this: L.Handler.PathTransform) {
    const latlngs = this._path.getLatLngs()[0];
    const lb = latlngs[0];
    const rt = latlngs[2];

    return new L.LatLng((lb.lat + rt.lat) / 2, (lb.lng + rt.lng) / 2);
  },

  /**
   * Hide(not remove) the handlers layer
   */
  _hideHandlers(this: L.Handler.PathTransform) {
    if (!this._handlersGroup) {
      throw new Error('No handlers group');
    }
    this._map.removeLayer(this._handlersGroup);
  },

  /**
   * Return an object representing a line of equation (ax + by + c = 0) with :
   *
   * @param ptA first point of the line
   * @param ptB second point of the line
   * @returns Line
   * @private
   */
  _line(this: L.Handler.PathTransform, ptA: L.Point, ptB: L.Point) {
    let a;
    let b;
    let c;
    let diviseur;

    if (ptB.y - ptA.y === 0) {
      a = 0;
      b = 1;
      c = -ptA.y;
      diviseur = 0;
    } else {
      diviseur = ptB.y - ptA.y;
      b = (ptA.x - ptB.x) / diviseur;
      c = (ptA.y * (ptB.x - ptA.x)) / diviseur - ptA.x;
      a = 1;
    }

    const equation = `${a}x + ${b}y + ${c} = 0`;

    const line: L.Line = { a, aPoint: ptA, b, bPoint: ptB, c, diviseur, equation };

    return line;
  },

  /**
   * Make handlers transparent
   */
  _makeHandlersApparent(this: L.Handler.PathTransform) {
    for (let i = this._handlers.length - 1; i >= 0; i -= 1) {
      this._handlers[i].setStyle({ color: '#ffffff', fillColor: '#ffffff' });
    }
  },

  /**
   * Make handlers transparent
   */
  _makeHandlersTransparent(this: L.Handler.PathTransform) {
    for (let i = this._handlers.length - 1; i >= 0; i -= 1) {
      this._handlers[i].setStyle({
        color: TRANSPARENT_COLOR,
        fillColor: TRANSPARENT_COLOR,
      });
    }
  },

  /**
   * Drag rectangle, re-create handlers
   */
  _onDragEnd(this: L.Handler.PathTransform) {
    if (!this._handlersGroup) {
      throw new Error('No handlers group');
    }
    this._map.addLayer(this._handlersGroup);
    this._updateHandlers();
  },

  /**
   * Hide handlers and rectangle
   */
  _onDragStart(this: L.Handler.PathTransform) {
    if (!this._map) return;
    this._map.scrollWheelZoom.disable();
    this._hideHandlers();
  },

  _onRotate(this: L.Handler.PathTransform, evt: L.LeafletMouseEvent) {
    if (!this._rotationStart || !this._rotationOriginPt || !this._map || !this._initialMatrix) {
      throw new Error('No enough information to apply rotation');
    }

    const pos = evt.layerPoint;
    const previous = this._rotationStart;
    const origin = this._rotationOriginPt;

    // rotation step angle
    this._angle =
      Math.atan2(pos.y - origin.y, pos.x - origin.x) -
      Math.atan2(previous.y - origin.y, previous.x - origin.x);

    this._matrix = this._initialMatrix.clone().rotate(this._angle, origin).flip();

    this._update();

    if (this._rotationIcon && this._rotationIcon.setLatLng && this._initialRotationIconLatLng) {
      const newLatLng = this._transformPoint(
        this._initialRotationIconLatLng,
        this._getProjectedMatrix(),
        this._map,
        this._map.getZoom(),
      );
      this._map.addLayer(this._rotationIcon);
      this._rotationIcon.setLatLng(newLatLng);
      if (this._rotationIcon.setStyle) {
        this._rotationIcon.setStyle({ 'z-index': LEAFLET_CURSOR_EDIT_ROTATION_ZINDEX });
      }
    }

    this._path.fire('rotate', {
      layer: this._path,
      rotation: this._angle,
    });
  },

  _onRotateEnd(this: L.Handler.PathTransform) {
    this._isRotating = false;
    const map = this._map;
    if (!map) {
      return;
    }
    map.off('mousemove', this._onRotate, this).off('mouseup', this._onRotateEnd, this);
    const angle = this._angle;
    this._apply();
    this._initialRotationIconLatLng = null;
    this._path.fire('rotateend', { layer: this._path, rotation: angle });
    this._map.scrollWheelZoom.enable();
  },

  _onRotateStart(evt: L.LeafletMouseEvent) {
    if (!this._map) {
      return;
    }

    this._isRotating = true;

    this._map.scrollWheelZoom.disable();

    const map = this._map;

    map.dragging.disable();

    this._originMarker = null;
    this._rotationOriginPt = map.latLngToLayerPoint(this._getRotationOrigin());
    this._rotationStart = evt.layerPoint;
    this._initialMatrix = this._matrix.clone();
    this._initialRotationIconLatLng = this._rotationIcon?._latlng;

    this._angle = 0;
    map.on('mousemove', this._onRotate, this).on('mouseup', this._onRotateEnd, this);

    this._cachePoints();
    this._path
      .fire('transformstart', { layer: this._path })
      .fire('rotatestart', { layer: this._path, rotation: 0 });
  },

  _onScaleEnd() {
    this._isScaling = false;
    if (this._map && this._mapDraggingWasEnabled) {
      this._map.dragging.enable();
    }

    if (!this._map) {
      return;
    }
    this._map.off('mousemove', this._onScaleStandard, this).off('mouseup', this._onScaleEnd, this);

    this._map.addLayer(this._handleLine);
    this._map.addLayer(this._rotationMarker);
    this._map.addLayer(this._rotationIcon);
    this._makeHandlersApparent();

    this._apply();
    this._path.fire('scaleend', {
      layer: this._path,
      scale: this._scale.clone(),
    });
    this._map.scrollWheelZoom.enable();
  },

  _onScaleStandard(this: L.Handler.PathTransform, evt: L.LeafletMouseEvent) {
    if (
      !this._map ||
      !this._lineOA ||
      !this._lineOB ||
      !this._ptA ||
      !this._ptB ||
      !this._ptO ||
      !this._activeMarker
    ) {
      throw new Error('No enough information to apply scaling');
    }

    const ptH = evt.latlng;
    let pHonLineOA = this._ptA.clone();
    if (this._lineOA.diviseur === 0) {
      pHonLineOA.lng = ptH.lng;
    } else if (this._lineOA.b === 0) {
      pHonLineOA.lat = ptH.lat;
    } else {
      pHonLineOA = this._map.layerPointToLatLng(
        getProjection(this._lineOA, this._map.latLngToLayerPoint(ptH)),
      );
    }

    let pHonLineOB = this._ptB.clone();
    if (this._lineOB.diviseur === 0) pHonLineOB.lng = ptH.lng;
    else if (this._lineOB.b === 0) pHonLineOB.lat = ptH.lat;
    else {
      pHonLineOB = this._map.layerPointToLatLng(
        getProjection(this._lineOB, this._map.latLngToLayerPoint(ptH)),
      );
    }

    const activeMarkerOptions = this._activeMarker.options as ExtendedCircleMarkerOptions;

    if (this._path._rings || this._path._parts) {
      const latlngs = this._path.getLatLngs()[0];

      for (let indexHandler = 0; indexHandler < 4; indexHandler += 1) {
        const handler = this._handlers[indexHandler];
        const handlerOptions = handler.options as ExtendedCircleMarkerOptions;
        let pathLatLng = latlngs[indexHandler];

        if (activeMarkerOptions.index === handlerOptions.index) {
          // We are on the point used for scaling
          handler._latlng.lat = evt.latlng.lat;
          handler._latlng.lng = evt.latlng.lng;
          if (pathLatLng) {
            pathLatLng.lat = evt.latlng.lat;
            pathLatLng.lng = evt.latlng.lng;
          } else {
            pathLatLng = new L.LatLng(evt.latlng.lat, evt.latlng.lng);
          }
        }

        if (activeMarkerOptions.index === (handlerOptions.index + 1) % 4 && pathLatLng) {
          // Point after the scaling point
          const BPrimeLatLng = pHonLineOB;
          handler._latlng.lat = BPrimeLatLng.lat;
          handler._latlng.lng = BPrimeLatLng.lng;
          if (pathLatLng) {
            pathLatLng.lat = BPrimeLatLng.lat;
            pathLatLng.lng = BPrimeLatLng.lng;
          }
        }

        if (activeMarkerOptions.index === (handlerOptions.index + 3) % 4 && pathLatLng) {
          // Point before the scaling point
          const APrimeLatLng = pHonLineOA;
          handler._latlng.lat = APrimeLatLng.lat;
          handler._latlng.lng = APrimeLatLng.lng;
          if (pathLatLng) {
            pathLatLng.lat = APrimeLatLng.lat;
            pathLatLng.lng = APrimeLatLng.lng;
          }
        }
      }
    }

    this._path._reset();

    this._update();
    this._path.fire('scale', {
      layer: this._path,
      scale: this._scale.clone(),
    });
  },

  _onScaleStart(this: L.Handler.PathTransform, evt: L.LeafletMouseEvent) {
    this._isScaling = true;
    if (!this._map) {
      return;
    }

    this._map.scrollWheelZoom.disable();

    const marker = evt.target;
    const map = this._map;

    if (map.dragging.enabled()) {
      map.dragging.disable();
      this._mapDraggingWasEnabled = true;
    }

    this._activeMarker = marker;

    this._originMarker = this._handlers[(marker.options.index + 2) % 4];
    this._scaleOrigin = this._originMarker.getLatLng();

    this._initialMatrix = this._matrix.clone();
    this._cachePoints();

    this._ptO = this._originMarker._latlng;
    const handlerA = this._handlers[(marker.options.index + 1) % 4];
    this._ptA = handlerA._latlng;
    const handlerB = this._handlers[(marker.options.index + 3) % 4];
    this._ptB = handlerB._latlng;

    // O : opposite of point selected in the handler
    // A and B : adjacent points
    this._lineOA = this._line(
      this._map.latLngToLayerPoint(this._ptO),
      this._map.latLngToLayerPoint(this._ptA),
    );
    this._lineOB = this._line(
      this._map.latLngToLayerPoint(this._ptO),
      this._map.latLngToLayerPoint(this._ptB),
    );

    this._map.on('mousemove', this._onScaleStandard, this);

    this._map.on('mouseup', this._onScaleEnd, this);

    this._path
      .fire('transformstart', { layer: this._path })
      .fire('scalestart', { layer: this._path, scale: L.point(1, 1) });

    if (this._handleLine) {
      this._map.removeLayer(this._handleLine);
    }
    if (this._rotationMarker) {
      this._map.removeLayer(this._rotationMarker);
    }
    if (this._rotationIcon) {
      this._map.removeLayer(this._rotationIcon);
    }
  },

  /**
   * Transform geometries separately
   */
  _transformGeometries(this: L.Handler.PathTransform) {
    if (!this._path) {
      throw new Error('No path defined');
    }
    this._path._transform(null);

    this._transformPoints(this._path);

    if (this._handleLine) {
      this._handleLine._transform(null);
      this._transformPoints(this._handleLine);
    }
  },

  /**
   * Apply transformation to a given point
   */
  _transformPoint(
    this: L.Handler.PathTransform,
    latlng: L.LatLng,
    matrix: L.Matrix,
    map: L.Map,
    zoom: number,
  ) {
    return map.unproject(matrix.transform(map.project(latlng, zoom)), zoom);
  },

  /**
   * Applies transformation, does it in one sweep for performance,
   * so don't be surprised about the code repetition.
   */
  _transformPoints(
    this: L.Handler.PathTransform,
    path: L.Path,
    angle?: number,
    scale?: L.Point,
    rotationOrigin?: L.LatLng,
    scaleOrigin?: L.LatLng,
  ) {
    if (!path.getMap()) {
      return;
    }
    const map = path.getMap();
    const zoom = map.getZoom() || 1;
    let i;
    let len;

    const projectedMatrix = this._getProjectedMatrix(angle, scale, rotationOrigin, scaleOrigin);
    this._projectedMatrix = projectedMatrix;

    // all shifts are in-place
    if (path._point) {
      if (!path._latlng) {
        throw new Error('Path does not have latlng');
      }
      // eslint-disable-next-line no-param-reassign
      path._latlng = this._transformPoint(path._latlng, projectedMatrix, map, zoom);
    } else if (path._rings || path._parts) {
      // everything else
      const rings = path._rings;

      if (!rings) {
        throw new Error('Path does not have rings');
      }

      if (!path._latlngs) {
        throw new Error('Path does not have latlngs');
      }

      const latlngs = (
        Array.isArray(path._latlngs[0]) ? path._latlngs : [path._latlngs]
      ) as L.LatLng[][];

      for (i = 0, len = rings.length; i < len; i += 1) {
        for (let j = 0, jj = rings[i].length; j < jj; j += 1) {
          latlngs[i][j] = this._transformPoint(latlngs[i][j], projectedMatrix, map, zoom);
        }
      }

      // eslint-disable-next-line no-param-reassign
      path._bounds = new L.LatLngBounds(latlngs[0][0], latlngs[0][0]);
      for (i = 0, len = rings.length; i < len; i += 1) {
        for (let j = 0, jj = rings[i].length; j < jj; j += 1) {
          path._bounds.extend(latlngs[i][j]);
        }
      }
    }

    path._reset();
  },

  /**
   * Update the polygon and handlers preview, no reprojection
   */
  _update(this: L.Handler.PathTransform) {
    if (!this._path) {
      return;
    }
    let matrix = this._matrix;

    // update handlers
    for (let i = 0, len = this._handlers.length; i < len; i += 1) {
      const handler = this._handlers[i];
      if (handler._initialPoint && handler !== this._originMarker) {
        handler._point = matrix.transform(handler._initialPoint);
        handler._updatePath();
      }
    }

    matrix = matrix.clone().flip();

    this._applyTransform(matrix);
    this._path.fire('transform', { layer: this._path });
  },

  /**
   * Recalculate rotation handlers position
   */
  _updateHandlers(this: L.Handler.PathTransform) {
    const handlersGroup = this._handlersGroup;

    if (!handlersGroup) {
      throw new Error('No handlers group');
    }

    if (this._handleLine) {
      handlersGroup.removeLayer(this._handleLine);
    }

    if (this._rotationIcon) {
      handlersGroup.removeLayer(this._rotationIcon);
    }

    if (this._rotationMarker) {
      handlersGroup.removeLayer(this._rotationMarker);
    }

    this._handleLine = null;
    this._rotationMarker = null;
    this._rotationIcon = null;

    for (let i = this._handlers.length - 1; i >= 0; i -= 1) {
      handlersGroup.removeLayer(this._handlers[i]);
    }

    this._createHandlers();
  },

  /**
   * Init interactions and handlers
   */
  addHooks(this: L.Handler.PathTransform) {
    this._createHandlers();
    this._path
      .on('dragstart', this._onDragStart, this)
      .on('scalestart', this._makeHandlersTransparent, this)
      .on('dragend', this._onDragEnd, this);
  },

  /**
   * @class L.Handler.PathTransform
   * @constructor
   * @param  {L.Path} path
   */
  initialize(this: L.Handler.PathTransform, path: L.KiliRectangle) {
    // references
    this._path = path;
    this._map = this._path.getMap();

    // handlers
    this._activeMarker = null;
    this._originMarker = null;
    this._rotationMarker = null;

    // origins & temporary state
    this._rotationOrigin = null;
    this._scaleOrigin = null;
    this._angle = 0;
    this._scale = L.point(1, 1);
    this._rotationStart = null;
    this._rotationOriginPt = null;

    // preview and transform matrix
    this._matrix = new L.Matrix(1, 0, 0, 1, 0, 0);
    this._projectedMatrix = new L.Matrix(1, 0, 0, 1, 0, 0);

    // ui elements
    this._handlersGroup = null;
    this._handlers = [];
    this._handleLine = null;
    this._rotationIcon = null;
    this._initialRotationIconLatLng = null;
  },

  /**
   * Remove handlers
   */
  removeHooks(this: L.Handler.PathTransform) {
    if (this._isRotating) {
      this._onRotateEnd();
    }
    if (this._isScaling) {
      this._onScaleEnd();
    }
    this._hideHandlers();
    this._path
      .off('dragstart', this._onDragStart, this)
      .off('scalestart', this._makeHandlersTransparent, this)
      .off('dragend', this._onDragEnd, this);
    this._handlersGroup = null;
    this._handlers = [];
  },
});

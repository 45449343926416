import _get from 'lodash/get';
import { createSelector } from 'reselect';

import {
  type HubspotSubscriptionStatus,
  OrganizationRole,
  ProjectRole,
  Right,
} from '../../__generated__/globalTypes';
import { getFullName } from '../../services/user/format';
import { projectUserRole } from '../project-user/selectors';
import { user } from '../selectors';
import { type State } from '../types';

export type ComputedProjectRole = ProjectRole | null;

export const userHubspotSubscriptionStatus = (state: State): HubspotSubscriptionStatus | null =>
  user(state)?.hubspotSubscriptionStatus || null;

export const userName = createSelector([user], userInStore =>
  getFullName(userInStore?.firstname, userInStore?.lastname),
);

export const userEmail = createSelector([user], userInStore => _get(userInStore, 'email', ''));

export const userOrganization = createSelector([user], userInStore => userInStore?.organization);

export const userFirstname = createSelector([user], userInStore => userInStore?.firstname ?? '');

export const userLastname = createSelector([user], userInStore => userInStore?.lastname ?? '');

export const userLicense = createSelector(
  [user],
  userInStore => userInStore?.organizations?.[0]?.license?.type ?? null,
);

export const userHasCompletedProfile = createSelector(
  [userFirstname, userLastname],
  (firstName, lastName) => Boolean(firstName && lastName),
);

export const userOrganizations = createSelector(
  [user],
  userInStore => userInStore?.organizations ?? null,
);

export const userPassword = createSelector([user], userInStore =>
  _get(userInStore, 'password', ''),
);

export const userRights = createSelector([user], userInStore =>
  _get(userInStore, 'rights', [] as Right[]),
);

export const userIsAllowedToUseIntegrationFeature = createSelector([userRights], rights => {
  const canUploadCloudData = rights.includes(Right.UPLOAD_CLOUD_DATA);
  return canUploadCloudData;
});

export const userIsAllowedToCreateLLMRLHFFeature = createSelector([userRights], rights => {
  return rights.includes(Right.CREATE_LLM_RLHF);
});

const defaultRole = OrganizationRole.USER;

export const userOrganizationRole = createSelector(
  [user],
  userInStore => userInStore?.organizationRole || defaultRole,
);

export const userIsReviewer = (state: State): boolean =>
  projectUserRole(state) === ProjectRole.REVIEWER;

export const userIsLabeler = (state: State): boolean =>
  projectUserRole(state) === ProjectRole.LABELER;

/* eslint-disable */
import L from 'leaflet';

import { KiliLayer } from '../../../Image/LeafletMapv2/KiliLeafletLayers/types';

class KiliSVGRenderer extends L.SVG {
  _defs: SVGDefsElement | undefined;

  _initContainer() {
    L.SVG.prototype._initContainer.call(this);

    // makes it possible to click through svg root; we'll reset it back in individual paths
    this._container.setAttribute('pointer-events', 'none');

    this._defs = L.SVG.create('defs');
    this._container.prepend(this._defs);
  }

  _destroyContainer() {
    L.SVG.prototype._destroyContainer.call(this);
    delete this._defs;
  }

  _initPath(layer: KiliLayer) {
    const path = (layer._defsPath = L.SVG.create('path'));
    const usePath = (layer._path = L.SVG.create('use'));

    if (layer.options.className) {
      usePath.classList.add(...L.Util.splitWords(layer.options.className));
    }

    if (layer.options.interactive) {
      usePath.classList.add('leaflet-interactive');
      usePath.setAttribute('pointer-events', 'auto');
    }

    path.setAttribute('id', `leaflet-path-${layer._leaflet_id.toString()}`);
    path.setAttribute('stroke-linecap', 'round');
    path.setAttribute('stroke-linejoin', 'round');
    path.style.setProperty('pointer-events', 'inherit');

    usePath.setAttribute('href', `#leaflet-path-${layer._leaflet_id.toString()}`);

    this._updateStyle(layer);

    if (!this._layers) {
      throw new Error('Layers not initialized');
    }

    this._layers[L.Util.stamp(layer)] = layer;

    if (layer?.kiliOptions?.shapeId) {
      layer.getElement()?.setAttribute('data-shape-id', layer?.kiliOptions?.shapeId);
    }
    if (layer?.kiliOptions?.mid) {
      layer.getElement()?.setAttribute('data-mid', layer?.kiliOptions?.mid);
    }
    if (layer?.kiliOptions?.dataCy) {
      layer.getElement()?.setAttribute('data-cy', layer?.kiliOptions?.dataCy);
    }
  }

  _addPath(layer: KiliLayer) {
    if (!this._defs) {
      this._initContainer();
    }

    if (!this._defs) {
      throw new Error('SVG container not initialized');
    }

    if (!layer._defsPath) {
      throw new Error('Path not initialized');
    }

    this._defs.appendChild(layer._defsPath);
    layer.addInteractiveTarget(layer._path);

    this._rootGroup.appendChild(layer._path);
  }

  _removePath(layer: KiliLayer) {
    layer._defsPath?.remove();
    layer.removeInteractiveTarget(layer._path);
    layer._path.remove();

    if (!this._layers) {
      throw new Error('Layers not initialized');
    }

    delete this._layers[L.Util.stamp(layer)];
  }

  _setPath(layer: KiliLayer, path: string) {
    layer._defsPath?.setAttribute('d', path);
  }

  // SVG does not have the concept of zIndex so we resort to changing the DOM order of elements
  _bringToFront(layer: KiliLayer) {
    L.DomUtil.toFront(layer._path);
    if (layer._selectedUsePath) {
      L.DomUtil.toFront(layer._selectedUsePath);
    }
  }

  _bringToBack(layer: KiliLayer) {
    L.DomUtil.toBack(layer._path);
  }

  _updateTransform() {
    // We deactivate temporarily this function to fix the zoom issues when using assets with lat closed to the lat limits
    // The right solution would be to fix the computations inside but we're waiting from answer on the leaflet-rotate repo issue
    // The _updateTransform does not seem necessary when using a leaflet map with zoomAnimation at False as the annotations
    // are always repositioned during the zoom.
  }

  addUsePath(usePath: SVGUseElement) {
    this._rootGroup.appendChild(usePath);
  }

  removeUsePath(usePath: SVGUseElement) {
    usePath.remove();
  }

  createMaskForLayer(layer: KiliLayer) {
    const mask = (layer._mask = L.SVG.create('mask'));
    mask.setAttribute('id', `mask-${layer._leaflet_id.toString()}`);
    const rect = L.SVG.create('rect');
    const useBlack = L.SVG.create('use');
    const useWhite = L.SVG.create('use');
    useBlack.setAttribute('href', `#leaflet-path-${layer._leaflet_id.toString()}`);
    useBlack.setAttribute('fill', 'black');
    useBlack.setAttribute('stroke-width', '3');
    useBlack.setAttribute('stroke', 'black');
    useWhite.setAttribute('href', `#leaflet-path-${layer._leaflet_id.toString()}`);
    useWhite.setAttribute('fill', 'white');
    useWhite.setAttribute('stroke-width', '7');
    useWhite.setAttribute('stroke', 'white');
    mask.appendChild(useWhite);
    mask.appendChild(useBlack);
    this._defs?.appendChild(mask);
    return mask;
  }

  removeMaskForLayer(layer: KiliLayer) {
    layer._mask?.remove();
  }

  _resetTransformPath(layer: KiliLayer) {
    layer._defsPath?.setAttributeNS(null, 'transform', '');
  }

  /**
   * Applies matrix transformation to SVG
   * @param {L.Path}         layer
   * @param {Array.<Number>} matrix
   */
  transformPath(layer: KiliLayer, matrix: L.MatrixArray) {
    layer._defsPath?.setAttributeNS(null, 'transform', 'matrix(' + matrix.join(' ') + ')');
  }
}

L.KiliSVGRenderer = KiliSVGRenderer;

import { type Draft } from 'immer';

import { type AssetLockSlice, type AssetLockSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: AssetLockSliceValues = {
  isReadOnly: false,
};

const log = createLogger('assetLock');

export const createAssetLockSlice: ZustandSlice<AssetLockSlice> = (set, _get) => ({
  ...initialState,
  updateField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.assetLock as Draft<AssetLockSliceValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
});

export * from './types';

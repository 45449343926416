import { createListenerMiddleware } from '@reduxjs/toolkit';

import { labelSaveUpdateField, updateField as zustandUpdateField } from '@/zustand';

import { LABEL_UPDATE } from './slice';

export const assetLabelListenerMiddleware = createListenerMiddleware();

assetLabelListenerMiddleware.startListening({
  actionCreator: LABEL_UPDATE,
  effect: action => {
    zustandUpdateField({
      key: 'latestSavedLabelId',
      sliceName: 'splitLabel',
      value: action.payload.id,
    });
    labelSaveUpdateField({
      path: 'clientVersion',
      value: action.payload.clientVersion ?? null,
    });
  },
});
